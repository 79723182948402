import React from 'react';
import { getWebStatus } from '../Api/deploymentManagerApi';

const Constants = require('./../Common/Constants');

export default class DeploymentStatusWeb extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestFailed: false };
    this.state = { duration: false };
  }

  componentDidMount() {
    var startDate = new Date();
    if (
      (this.props.project !== Constants.APIProject &&
        this.props.project !== Constants.RSAPIProject &&
        this.props.project !== Constants.DMSProject) ||
      this.props.previousVersion === 'true'
    ) {
      getWebStatus(this.props.environment, this.props.project)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            this.setState({ requestFailed: true });
            this.setState({ buildInfoData: response.statusText });
            var endDate = new Date();
            this.setState({ duration: endDate.getTime() - startDate.getTime() });
            return response.statusText;
          }
        })
        .then(json => {
          var endDate = new Date();
          this.setState({ duration: endDate.getTime() - startDate.getTime() });
        });
    }
  }

  render() {
    if (
      (this.props.project !== Constants.WebProject &&
        this.props.project !== Constants.MobileProject &&
        this.props.project !== Constants.AdminProject &&
        this.props.environment !== Constants.LegacyEnv) ||
      this.props.previous === 'true'
    )
      return null;
    if (this.state.requestFailed)
      return (
        <div className="webfailed">
          {' '}
          <b>Failed</b> {this.state.buildInfoData}{' '}
        </div>
      );
    if (!this.state.duration)
      return (
        <div className="webloading">
          {' '}
          <b>Loading...</b> {this.state.buildInfoData}{' '}
        </div>
      );
    if (
      this.props.environment === Constants.LegacyEnv
    ) {
      return (
        <div>
        <b>Legacy Status - Response time:</b> {this.state.duration} ms
        </div>
      );
    } else {
      return (
        <div>
          <p>
            <b> *Response time:</b> {this.state.duration} ms
          </p>
        </div>
      );
    }
  }
}
