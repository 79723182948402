import React from 'react';
import { getBuildInfoFromDM } from '../Api/deploymentManagerApi';
import { getBuildInfo } from '../Api/buildinfoApi';
import DeploymentStatusWeb from '../Components/deploymentStatusWeb';
import '../assets/css/common.css';
import '../assets/css/home.css';

const Constants = require('./../Common/Constants');

export default class DeploymentStatusItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestFailed: false };
  }

  // Build info for API and RS come firectly from ther services. All others from the DM
  componentDidMount() {
    var startDate = new Date();
    if (
      (this.props.project !== Constants.APIProject && this.props.project !== Constants.RSAPIProject) ||
      this.props.previousVersion === 'true'
    ) {
      getBuildInfoFromDM(this.props.environment, this.props.project, this.props.previousVersion)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            this.setState({ requestFailed: true });
            return response.statusText;
          }
        })
        .then(json => {
          this.setState({ buildInfoData: json });
        });
    } else {
      getBuildInfo(this.props.token, this.props.environment, this.props.project)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            this.setState({ requestFailed: true });
            return response.statusText;
          }
        })
        .then(json => {
          this.setState({ buildInfoData: json });
          var endDate = new Date();
          this.setState({ duration: endDate.getTime() - startDate.getTime() });
        });
    }
  }

  render() {
    if (this.state.requestFailed)
      return (
        <div className="buildFailed">
          <p> Failed: {this.state.buildInfoData}</p>
        </div>
      );
    if (!this.state.buildInfoData)
      return (
        <div className="buildLoading">
          <p> Loading...</p>
        </div>
      );
    if (this.state.buildInfoData.buildStatus !== 'OK' && this.props.previousVersion === 'true') {
      this.props.action(this.props.buttonId); // Set RevertButtonStatus
    }
    if (
      (this.props.project !== Constants.APIProject &&
        this.props.project !== Constants.RSAPIProject) ||
      this.props.previousVersion === 'true'
    ) {
      var classname = 'buildSuccessDM';
      if (this.state.buildInfoData.buildStatus !== 'OK')
        classname = 'buildFailureDM';
      return (
        <div className={classname}>
          <DeploymentStatusWeb
            environment={this.props.environment}
            project={this.props.project}
            previous={this.props.previousVersion}
          />
          <b>Build Status:</b> {this.state.buildInfoData.buildStatus}
          <br />
          <b>Commit Author: </b> {this.state.buildInfoData.commitAuthor}
          <br />
          <b>Date: </b> {this.state.buildInfoData.commitDate}
          <br />
          <b>Message: </b>
          {this.state.buildInfoData.commitMessage}
          <br />
        </div>
      );
    } else {
      classname = 'buildSuccess';
      if (this.state.buildInfoData.buildStatus !== 'OK') classname = 'buildFailure';
      return (
        <div className={classname}>
          <b>Build Status:</b> {this.state.buildInfoData.buildStatus}
          <br />
          <b>Build Date: </b> {this.state.buildInfoData.buildDate}
          {this.state.buildInfoData.runEnvironment == "Production" &&
          <div>
          <b>Load balancing: </b> {this.state.buildInfoData.loadBalancingStatus}
          </div>
          }
          <br />
          <br />
          <b>Machine:</b> {this.state.buildInfoData.region + "/" + this.state.buildInfoData.machineName}
          <br />
          <b>App Pool:</b> {this.state.buildInfoData.appPool}
          <br />
          <b>Run Environment:</b> {this.state.buildInfoData.runEnvironment}
          <br />
          <br />
          <b>Commit Author: </b> {this.state.buildInfoData.commitAuthor}
          <br />
          <b>Date: </b> {this.state.buildInfoData.commitDate}
          <br />
          <b>Message: </b>
          {this.state.buildInfoData.commitMessage}
          <br />
          <br />
          <b>API Response Time: </b>
          {this.state.buildInfoData.duration} ms
          <br />
          <b>Overall Response Time: </b>
          {this.state.duration} ms
        </div>
      );
    }
  }
}
