import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');

export function getLatestCommit(environment, project) {
  var url = Urls.git;
  if (project === Constants.APIProject) url += '/TRMS20';
  else if (project === Constants.RSAPIProject) url += '/TRMS20RS';
  else if (project === Constants.WebProject) url += '/TRMS20-Web';
  else if (project === Constants.MobileProject) url += '/TRMS20-Mobile';
  else if (project === Constants.AdminProject) url += '/TRMSAdminUI';
  else if (project === Constants.DMSProject) url += '/TofinoDevOps';

  if (environment === Constants.TestEnv && project != Constants.DMSProject) url += '/commits/develop';
  else if (environment === Constants.StagingEnv || project == Constants.DMSProject) url += '/commits/master';


  const user = 'gskaufman';
  const token = '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';
  const creds = `${user}:${token}`;
  const auth = btoa(creds);

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Basic ' + auth,
    },
  }).then(response => {
    return response;
  });

}
