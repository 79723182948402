import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');

export function getBuildInfoFromDM(environment, project, previous) {
  var url = Urls.deploymentManager;
  if (environment === Constants.ProdEnv)
    url = Urls.deploymentManagerProd;
  url += '?environment=' + environment;
  if (environment === Constants.ProdEnvB)
  {
    url = Urls.deploymentManagerProdB;
    url += '?environment=PROD';
  }
  url += '&project=' + project;
  if (previous === 'true')
    url += '&previousVersion=true';

  console.log("*BuildInfoDM:",url);
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then(response => {
    return response;
  }).catch((e) => {
    return e;
}) ;
}

export function getWebStatus(environment, project) {
  // This calls the deployment manager which then calls the web ui (to avoid cors issues)
  var url = Urls.deploymentManager;
  if (environment.includes(Constants.ProdEnvB))
  {
     url = Urls.deploymentManagerProdB;
     environment = environment.replace("PRODB","PROD");
  }
  else if (environment.includes(Constants.ProdEnv))
     url = Urls.deploymentManagerProd;

  url += '/web?environment=' + environment;
  url += '&project=' + project;
  url += '&mode=' + Urls.currentConnection;

  console.log("*WebStatus:",url);

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then(response => {
      return response;
  }).catch((e) => {
    return e;
}) ;
}

export function deploy(deployProject) {
  var project = deployProject;
  var url = Urls.deploymentManager;
  if (deployProject.includes(Constants.ProdEnvB))
  {
     url = Urls.deploymentManagerProdB;
     project = project.replace("PRODB","PROD");
  }
  else if (deployProject.includes(Constants.ProdEnv))
     url = Urls.deploymentManagerProd;
  url += '/deploy';

  console.log("*Deploy:",url);

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authSecret: Constants.authSecret,
    },
    body: JSON.stringify(project),
  }).then(response => {
    if (response.ok) {
      alert('Response: OK');
      return response;
    } else alert('Response: ' + response.statusText);
  }).catch((e) => {
    return e;
}) ;
}

export function revert(deployProject) {
  var project = deployProject;
  var url = Urls.deploymentManager;
  if (deployProject.includes(Constants.ProdEnvB))
  {
     url = Urls.deploymentManagerProdB;
     project = project.replace("PRODB","PROD");
  }
  else if (deployProject.includes(Constants.ProdEnv))
     url = Urls.deploymentManagerProd;

  url += '/revert';

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      authSecret: Constants.authSecret,
    },
    body: JSON.stringify(project),
  }).then(response => {
    if (response.ok) {
      alert('Response: OK');
      return response;
    } else alert('Response: ' + response.statusText);
  }).catch((e) => {
    return e;
}) ;
}
