import React, { Component } from 'react';
const Constants = require('./../Common/Constants');

export default class LanguageStrings extends Component {
  render() {
    var tableName = Constants.LanguageTableTest;
    if (this.props.environment === Constants.StagingEnv) tableName = Constants.LanguageTableStaging;
    if (this.props.environment === Constants.ProdEnv) tableName = Constants.LanguageTableProd;
    if (this.props.environment === Constants.ProdEnvB) tableName = Constants.LanguageTableProdB;

    var words = sessionStorage.getItem(tableName);
    if (!words) return <p> Loading...</p>;

    //var arr = obj.split(',');
    var arr = words.split('","');

    if (this.props.id <= arr.length) return arr[this.props.id - 1];
    else return 'Unable to find: ' + this.props.id;
  }
}
