export function post(token, url, body) {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }).then(response => {
    return response;
  });
}
