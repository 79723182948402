import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');

export function getAssetCount(token, environment) {
  var url = '';
  if (environment === Constants.TestEnv) url = Urls.getAssetCountTest;
  else if (environment === Constants.StagingEnv) url = Urls.getAssetCountStaging;
  else if (environment === Constants.ProdEnv) url = Urls.getAssetCountProd;
  else throw Error('Not implemented ');

  var filterBody = {
    tenant: 'tofino',
  };

  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(filterBody),
  }).then(response => {
    return response;
  });
}
