import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Home from './Pages/home';
import Login from './Pages/login';
import SmokeTest from './Pages/smoketest';
import DBUpdate from './Pages/dbupdate';
import Provision from './Pages/provision';
import Language from './Pages/language';
import SystemUsage from './Pages/systemusage';

export default ({ childProps }) => (
  <Routes>
     <Route  path="/" element={<Login props={childProps} />} />
     <Route  path="/login" element={<Login props={childProps} />} />
     <Route  path="/home" element={<RequireAuth redirectTo="/login"><Home/></RequireAuth>} />
     <Route  path="/smoketest" element={<RequireAuth redirectTo="/login"><SmokeTest/></RequireAuth>} />
     <Route  path="/language" element={<RequireAuth redirectTo="/login"><Language/></RequireAuth>} />
     <Route  path="/provision" element={<RequireAuth redirectTo="/login"><Provision/></RequireAuth>} />
     <Route  path="/dbupdate" element={<RequireAuth redirectTo="/login"><DBUpdate/></RequireAuth>} />
     <Route  path="/systemusage" element={<RequireAuth redirectTo="/login"><SystemUsage/></RequireAuth>} />

    {/* Finally, catch all unmatched routes */}
    <Route element={<Login props={childProps} />} />

    </Routes>
);

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = sessionStorage.getItem('isAuthenticated') ? true :false;
  return isAuthenticated ? children : <Navigate to={redirectTo} />;
}
