import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');

export function reloadLanguages(token, target) {
  var url = '';
  if (target === Constants.TestEnv) url = Urls.reloadLanguagesTest;
  else if (target === Constants.StagingEnv) url = Urls.reloadLanguagesStaging;
  else if (target === Constants.ProdEnv) url = Urls.reloadLanguagesProd;
  else if (target === Constants.ProdEnvB) url = Urls.reloadLanguagesProdB;
  else throw Error('Not implemented ');

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => {
    return response;
  });
}

// ----------------------------------------------------------------

export function getLanguages(token, environment) {
  var url = '';
  console.log(environment);
  if (environment === Constants.TestEnv) url = Urls.getLanguagesTest;
  else if (environment === Constants.StagingEnv) url = Urls.getLanguagesStaging;
  else if (environment === Constants.ProdEnv) url = Urls.getLanguagesProd;
  else if (environment === Constants.ProdEnvB) url = Urls.getLanguagesProdB;
  else throw Error('Not implemented ');
  console.log(url);

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => {
    return response;
  });
}
