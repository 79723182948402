import React from 'react';
import { getAssetCount } from '../Api/assetsApi';

export default class Asset extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestFailed: false };
  }

  componentDidMount() {
    var startDate = new Date();
    getAssetCount(this.props.token, this.props.environment)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({ requestFailed: true });
          return response.statusText;
        }
      })
      .then(json =>{
        this.setState({ buildInfoData: json.recordCount });
        var endDate = new Date();
        this.setState({ duration: endDate.getTime() - startDate.getTime() });
      });
  }

  render() {
    if (this.state.requestFailed) return <p> Failed: {this.state.buildInfoData}</p>;
    if (!this.state.buildInfoData) return <p> Loading...</p>;

    return (
      <div>
        <b>Asset Count:</b> {this.state.buildInfoData} ( {this.state.duration} ms)
      </div>
    );
  }
}
