import React, { Component, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Navbar, Nav, NavItem } from 'react-bootstrap';
import { SideNav } from 'react-sidenav';
import AdminRoutes from './adminRoutes';
import logo from './assets/tofino.jpg';
import './assets/css/app.css';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: sessionStorage.getItem('token'),
      isAuthenticated: sessionStorage.getItem('isAuthenticated'),
    };
  }

  handleLogout = event => {
    sessionStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('token');
    this.props.navigate('/login');
  };

  render() {
    const childProps = {
      isAuthenticated: sessionStorage.getItem('isAuthenticated'),
      token: sessionStorage.getItem('token'),
    };

    this.state = {
      token: sessionStorage.getItem('token'),
      isAuthenticated: sessionStorage.getItem('isAuthenticated'),
    };

    if (this.state.isAuthenticated) {
      return (
        <div className="App container">
          <div className="row">
            <div className="leftColumn">
              <img src={logo} alt="logo" />
            </div>
            <div className="rightColumn">
              <h2>Tofino System Administration</h2>
            </div>
          </div>
          <Navbar collapseOnSelect >
              <Navbar.Brand />
            <Nav className="navbar-nav ml-auto">
              {this.state.isAuthenticated ? (
                <NavItem  onClick={this.handleLogout}>Logout</NavItem>
              ) : (
                <Fragment>
                  <LinkContainer to="/login">
                    <NavItem>Login</NavItem>
                  </LinkContainer>
                </Fragment>
              )}
            </Nav>
          </Navbar>

          <div className="sidenav">
            <SideNav
              className="sidenav nav"
              highlightColor="#019cf9"
              highlightBgColor="#00bcd4"
              defaultSelected="Home"
            >
              <Nav>
                {' '}
                <Link to="/home">Home</Link>{' '}
              </Nav>
              <Nav>
                {' '}
                <Link to="/smoketest"> Smoke Test</Link>{' '}
              </Nav>
              <Nav>
                {' '}
                <Link to="/language"> Languages</Link>{' '}
              </Nav>
              <Nav>
                {' '}
                <Link to="/provision"> Provision</Link>{' '}
              </Nav>
              <Nav>
                {' '}
                <Link to="/dbupdate"> DB Update</Link>{' '}
              </Nav>
              <Nav>
                {' '}
                <Link to="/systemusage"> System Usage</Link>{' '}
              </Nav>
            </SideNav>
          </div>
          <div>
            <AdminRoutes childProps={childProps} />
          </div>
        </div>

      );
    } else
      return (

        <div className="App container">
          <h2>Tofino System Administration</h2>
          <AdminRoutes childProps={childProps} />
        </div>

      );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <App {...props} navigate={navigate} />
}

export default WithNavigate
