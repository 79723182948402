import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');


export function getBuildInfo(token, environment, project) {
  var url = '';
  if (project === Constants.RSAPIProject) {
    if (environment === Constants.TestEnv) url = Urls.buildInfoRSTest;
    else if (environment === Constants.StagingEnv) url = Urls.buildInfoRSStaging;
    else if (environment === Constants.ProdEnv) url = Urls.buildInfoRSProd;
    else url = Urls.buildInfoRSProdB;
  } else {
    if (environment === Constants.TestEnv) url = Urls.buildInfoTest;
    else if (environment === Constants.StagingEnv) url = Urls.buildInfoStaging;
    else if (environment === Constants.ProdEnv) url = Urls.buildInfoProd;
    else url = Urls.buildInfoProdB;
  }
  console.log("*BuildInfo:",url);

  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      return response;
    })
    .catch(e => {
      return e;
    });
}

export function postBuildInfo(token) {
  return fetch(Urls.buildInfoTest, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
  })
    .then(response => {
      return response;
    })
    .catch(e => {
      return e;
    });
}
