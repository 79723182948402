import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Job from '../Components/job';
import Asset from '../Components/asset';
import Operator from '../Components/operator';
import '../assets/css/common.css';
import '../assets/css/smoketest.css';
import '../..//node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const Constants = require('./../Common/Constants');

export default class SmokeTest extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    this.populateDevColumn = this.populateDevColumn.bind(this);
    this.populateStagingColumn = this.populateStagingColumn.bind(this);
    this.populateProdColumn = this.populateProdColumn.bind(this);
  }

  populateColumn1(cell, row) {
    return <div className="LabelColumn">{cell}</div>;
  }

  populateDevColumn(cell, row, enumObject, index) {
    return (
      <div>
        <Job token={sessionStorage.getItem('token')} environment={Constants.TestEnv} />
        <Asset token={sessionStorage.getItem('token')} environment={Constants.TestEnv} />
        <Operator token={sessionStorage.getItem('token')} environment={Constants.TestEnv} />
      </div>
    );
  }

  populateStagingColumn(cell, row, enumObject, index) {
    return (
      <div>
        <Job token={sessionStorage.getItem('token')} environment={Constants.StagingEnv} />
        <Asset token={sessionStorage.getItem('token')} environment={Constants.StagingEnv} />
        <Operator token={sessionStorage.getItem('token')} environment={Constants.StagingEnv} />
      </div>
    );
  }

  populateProdColumn(cell, row, enumObject, index) {
    return (
      <div>
        <Job token={sessionStorage.getItem('token')} environment={Constants.ProdEnv} />
        <Asset token={sessionStorage.getItem('token')} environment={Constants.ProdEnv} />
        <Operator token={sessionStorage.getItem('token')} environment={Constants.ProdEnv} />
      </div>
    );
  }

  componentDidMount(newProps) {
    this.setState({
      data: [
        {
          label: 'Result',
          dev: 'Dev',
          staging: 'Staging',
          prod: 'Prod',
        },
      ],
    });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="Language">
          <div className="main">
            <div className="ContentSection">
              <h4>
                <br />
                Smoke Test
              </h4>
              <br />
            </div>
            <div className="ContentSection">
              <BootstrapTable data={this.state.data}>
                <TableHeaderColumn
                  width="80"
                  dataField="label"
                  dataFormat={this.populateColumn1}
                  isKey
                />
                <TableHeaderColumn dataField="dev" dataFormat={this.populateDevColumn}>
                  Test
                </TableHeaderColumn>
                <TableHeaderColumn dataField="staging" dataFormat={this.populateStagingColumn}>
                  Staging
                </TableHeaderColumn>
                <TableHeaderColumn dataField="prod" dataFormat={this.populateProdColumn}>
                  Prod
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </form>
    );
  }
}



