import React, { Component } from 'react';
import { Button, Select, Input, message } from 'antd';
import { post } from '../Api/restRequests';
import 'antd/dist/antd.min.css';

import Urls from './../Common/Urls';

export default class Provision extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.provision = this.provision.bind(this);
    this.handleRegChange = this.handleRegChange.bind(this);
  }

  componentDidMount(newProps) {

    post(sessionStorage.getItem('token'), Urls.getRegistrationList, '')
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          console.log(response.statusText);
        }
      })
      .then(result => {
        var arrTen = [];
        for (var k = 0; k < result.items.length; k++) {
          arrTen.push(
            <option key={result.items[k].id} value={result.items[k].id}>
              {result.items[k].id} {result.items[k].company} ({result.items[k].subdomain})
            </option>
          );
        }
        this.setState({
          registrationList: arrTen,
        });
      });
  }

  provision(e) {
    if (this.state.registrationId === null || this.state.registrationId === undefined || this.state.registrationId === '') {
      alert('Must select registration record');
      return;
    }
    if ( this.state.actualDomain === null || this.state.actualDomain === undefined  || this.state.actualDomain === '') {
      alert('Must specify new domain');
      return;
    }
    if (this.state.tenantType === null || this.state.tenantType === undefined) {
      alert('Must specify tenant type');
      return;
    }
    if (this.state.tenantSource === null || this.state.tenantSource === undefined) {
      alert('Must specify tenant source');
      return;
    }

    try {
      var provisionDto = {
        registrationId: this.state.registrationId,
        subdomain: this.state.actualDomain,
        tenantType: this.state.tenantType,
        tenantSource: this.state.tenantSource,
      };
      post(sessionStorage.getItem('token'), Urls.provision, provisionDto).then(response => {
        if (!response.ok) {
          alert('Request failed: ' + response.status);
          return;
        }
      });

      message.info('Request Sent');
    } catch (e) {
      message.info(e.message);
    }
  }

  handleRegChange(value) {
    this.setState({
      registrationId: value,
    });

    console.log(`selected ${value}`);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <div className="Provision">
        <div className="lander">
          <div className="leftColumn">
            <br />
            <h4>&nbsp;Provision New Tenant</h4>
            <br />
            <div>
              Registration Record:&nbsp;
              <Select style={{ width: 600 }} onChange={this.handleRegChange}>
                {this.state.registrationList}
              </Select>
            </div>
            <div>
              <br />
              <br />
              Actual domain name:&nbsp;
              <Input name="actualDomain" style={{ width: 200 }} onBlur={this.handleChange} />
              <div className="radio-buttons" />
              <br />
              Tenant Type:&nbsp;&nbsp;
              <input
                id="trms"
                value="1"
                name="tenantType"
                type="radio"
                onChange={this.handleChange}
              />
              &nbsp;Trms&nbsp;&nbsp;
              <input
                id="dcrib"
                value="0"
                name="tenantType"
                type="radio"
                onChange={this.handleChange}
              />
              &nbsp;Dcrib&nbsp;&nbsp;
              <input
                id="portal"
                value="2"
                name="tenantType"
                type="radio"
                onChange={this.handleChange}
              />
              &nbsp;Portal&nbsp;&nbsp;
            </div>
            <div className="radio-buttons">
              <br />
              Tenant Source:&nbsp;&nbsp;
              <input
                id="trms"
                value="Tofino"
                name="tenantSource"
                type="radio"
                onChange={this.handleChange}
              />
              &nbsp;Tofino&nbsp;&nbsp;
              <input
                id="dcrib"
                value="Fastenal"
                name="tenantSource"
                type="radio"
                onChange={this.handleChange}
              />
              &nbsp;Fastenal&nbsp;&nbsp;
              <input
                id="portal"
                value="Cardinal"
                name="tenantSource"
                type="radio"
                onChange={this.handleChange}
              />
              &nbsp;Cardinal&nbsp;&nbsp;
            </div>

            <br />
            <Button onClick={this.provision}>Provision</Button>
          </div>
        </div>
      </div>
    );
  }
}
