import React from 'react';
import { getLanguages } from '../Api/languageApi';

const Constants = require('./../Common/Constants');

export default class LanguageStringCount extends React.Component {
  constructor(props) {
    super(props);
    this.state = { requestFailed: false };
  }

  componentDidMount() {
    var startDate = new Date();
    getLanguages(this.props.token, this.props.environment)
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          this.setState({ requestFailed: true });
          return response.statusText;
        }
      })
      .then(json => {
        var words = JSON.stringify(Object.values(json));
        words = words.substring(2);
        words = words.replace('"]', '');

        var count = Object.keys(json).length;
        this.setState({ languageCount: count });

        var tableName = Constants.LanguageTableTest;
        if (this.props.environment === Constants.StagingEnv)
          tableName = Constants.LanguageTableStaging;
        if (this.props.environment === Constants.ProdEnv) tableName = Constants.LanguageTableProd;
        if (this.props.environment === Constants.ProdEnvB) tableName = Constants.LanguageTableProdB;
        sessionStorage.removeItem(tableName);
        sessionStorage.setItem(tableName, words);

        var endDate = new Date();
        this.setState({ duration: endDate.getTime() - startDate.getTime() });
      });
  }

  render() {
    if (this.state.requestFailed) return <p> Failed: {this.state.languageData}</p>;
    if (!this.state.languageCount) return <p> Loading...</p>;
    return (
      <div>
        {this.state.languageCount} ({this.state.duration}ms)
      </div>
    );
  }
}
