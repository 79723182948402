import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');

export function getJobCount(token, environment) {
  var url = '';
  if (environment === Constants.TestEnv) url = Urls.getJobCountTest;
  else if (environment === Constants.StagingEnv) url = Urls.getJobCountStaging;
  else if (environment === Constants.ProdEnv) url = Urls.getJobCountProd;
  else throw Error('Not implemented ');

  var jobFilterBody = {
    tenant: 'tofino',
  };
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(jobFilterBody),
  }).then(response => {
    return response;
  });
}
