import {currentConn} from '../config';
import {baseUrlTest} from '../config';
import {baseUrlStaging} from '../config';
import {baseUrlProd} from '../config';
import {baseUrlDeployProdA} from '../config';
import {baseUrlDeployProdB} from '../config';

export default {
  currentConnection: currentConn,
  loginTofino: 'https://tofino1.com/api/login',
  loginTrmstest: 'https://trmstest.com/api/login',

  // Routes for home page build status
  git: 'https://api.github.com/repos/tofinosoftware',
  legacyLogin: 'http://tofino1.com/TRMSCustomerFiles/CustomerImages/logos/tofino.jpg',

  buildInfoTest: baseUrlTest + 'api/admin',
  buildInfoStaging: baseUrlStaging + 'api/admin',
  buildInfoProd: baseUrlDeployProdA + 'api/admin',
  buildInfoProdB: baseUrlDeployProdB + 'api/admin',

  buildInfoRSTest: baseUrlTest + 'report/admin',
  buildInfoRSStaging: baseUrlStaging + 'report/admin',
  buildInfoRSProd: baseUrlDeployProdA + 'report/admin',
  buildInfoRSProdB: baseUrlDeployProdB + 'report/admin',

  deploymentManager: baseUrlTest + 'dms/deployments',
  deploymentManagerProd: baseUrlDeployProdA + 'dms/deployments',
  deploymentManagerProdB: baseUrlDeployProdB + 'dms/deployments',

  // Smoke Test
  getJobCountTest: baseUrlTest + 'api/jobs/list',
  getJobCountStaging: baseUrlStaging + 'api/jobs/list',
  getJobCountProd: baseUrlProd + 'api/jobs/list',

  getAssetCountTest: baseUrlTest + 'api/assets/list',
  getAssetCountStaging: baseUrlStaging + 'api/assets/list',
  getAssetCountProd: baseUrlProd + 'api/assets/list',

  getOperatorCountTest: baseUrlTest + 'api/operators/list',
  getOperatorCountStaging: baseUrlStaging + 'api/operators/list',
  getOperatorCountProd: baseUrlProd + 'api/operators/list',

  // Language Page
  reloadLanguagesTest: baseUrlTest + 'api/localization/reload',
  reloadLanguagesStaging: baseUrlStaging + 'api/localization/reload',
  reloadLanguagesProd: baseUrlDeployProdA + 'api/localization/reload',
  reloadLanguagesProdB: baseUrlDeployProdB + 'api/localization/reload',

  getLanguagesTest: baseUrlTest + 'api/localization/en',
  getLanguagesStaging: baseUrlStaging + 'api/localization/en',
  getLanguagesProd: baseUrlDeployProdA + 'api/localization/en',
  getLanguagesProdB: baseUrlDeployProdB + 'api/localization/en',

  // Provision - uses test domain
  getRegistrationList: baseUrlTest + 'api/registration/list',
  provision: baseUrlTest + 'api/admin/createschema',
};
