import React from 'react';
import { Button, ToggleButton } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DeploymentStatusItem from '../Components/deploymentStatusItem';
import GitLatestCommit from '../Components/gitLatestCommit';
import { deploy, revert } from '../Api/deploymentManagerApi';
import '../assets/css/common.css';
import '../assets/css/home.css';
import '../..//node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import { AdminProject, DMSProject } from './../Common/Constants';

const Constants = require('./../Common/Constants');

export default class DeploymentStatusTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
    this.populateDevColumn = this.populateDevColumn.bind(this);
    this.populateStagingColumn = this.populateStagingColumn.bind(this);
    this.populateProdColumn = this.populateProdColumn.bind(this);
    this.populateProdColumnB = this.populateProdColumnB.bind(this);
    this.SetRevertButtonStatus = this.SetRevertButtonStatus.bind(this);
    this.ToggleDetail = this.ToggleDetail.bind(this);

    this.state = { showDetail: false };
  }

  Deploy(e) {
    var target = '';
    if (e.target.id === 'DeployTESTWEB') target = Constants.TestEnv + '_' + Constants.WebProject;
    else if (e.target.id === 'DeploySTAGINGWEB')
      target = Constants.StagingEnv + '_' + Constants.WebProject;
    else if (e.target.id === 'DeployPRODWEB')
      target = Constants.ProdEnv + '_' + Constants.WebProject;
    else if (e.target.id === 'DeployPRODBWEB')
      target = Constants.ProdEnvB + '_' + Constants.WebProject;
    else if (e.target.id === 'DeployTESTMOBILE')
      target = Constants.TestEnv + '_' + Constants.MobileProject;
    else if (e.target.id === 'DeploySTAGINGMOBILE')
      target = Constants.StagingEnv + '_' + Constants.MobileProject;
    else if (e.target.id === 'DeployPRODMOBILE')
      target = Constants.ProdEnv + '_' + Constants.MobileProject;
    else if (e.target.id === 'DeployPRODBMOBILE')
      target = Constants.ProdEnvB + '_' + Constants.MobileProject;
    else if (e.target.id === 'DeployTESTAPI')
      target = Constants.TestEnv + '_' + Constants.APIProject;
    else if (e.target.id === 'DeploySTAGINGAPI')
      target = Constants.StagingEnv + '_' + Constants.APIProject;
    else if (e.target.id === 'DeployPRODAPI')
      target = Constants.ProdEnv + '_' + Constants.APIProject;
    else if (e.target.id === 'DeployPRODBAPI')
      target = Constants.ProdEnvB + '_' + Constants.APIProject;
    else if (e.target.id === 'DeployTESTRSAPI')
      target = Constants.TestEnv + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'DeploySTAGINGRSAPI')
      target = Constants.StagingEnv + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'DeployPRODRSAPI')
      target = Constants.ProdEnv + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'DeployPRODBRSAPI')
      target = Constants.ProdEnvB + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'DeployTESTADMIN')
      target = Constants.TestEnv + '_' + Constants.AdminProject;
    else if (e.target.id === 'DeployTESTDMS')
      target = Constants.TestEnv + '_' + Constants.DMSProject;
    else if (e.target.id === 'DeployPRODDMS')
      target = Constants.ProdEnv + '_' + Constants.DMSProject;
    else if (e.target.id === 'DeployPRODBDMS')
      target = Constants.ProdEnvB + '_' + Constants.DMSProject;

    if (!window.confirm('Are you sure you want to DEPLOY ' + target + '?')) return;
    deploy(target);
    //alert("Deploying: " + target)
  }

  Revert(e) {
    var target = '';
    if (e.target.id === 'RevertTESTWEB') target = Constants.TestEnv + '_' + Constants.WebProject;
    else if (e.target.id === 'RevertSTAGINGWEB')
      target = Constants.StagingEnv + '_' + Constants.WebProject;
    else if (e.target.id === 'RevertPRODWEB')
      target = Constants.ProdEnv + '_' + Constants.WebProject;
    else if (e.target.id === 'RevertPRODBWEB')
      target = Constants.ProdEnvB + '_' + Constants.WebProject;
    else if (e.target.id === 'RevertTESTMOBILE')
      target = Constants.TestEnv + '_' + Constants.MobileProject;
    else if (e.target.id === 'RevertSTAGINGMOBILE')
      target = Constants.StagingEnv + '_' + Constants.MobileProject;
    else if (e.target.id === 'RevertPRODMOBILE')
      target = Constants.ProdEnv + '_' + Constants.MobileProject;
    else if (e.target.id === 'RevertPRODBMOBILE')
      target = Constants.ProdEnvB + '_' + Constants.MobileProject;
    else if (e.target.id === 'RevertTESTAPI')
      target = Constants.TestEnv + '_' + Constants.APIProject;
    else if (e.target.id === 'RevertSTAGINGAPI')
      target = Constants.StagingEnv + '_' + Constants.APIProject;
    else if (e.target.id === 'RevertPRODAPI')
      target = Constants.ProdEnv + '_' + Constants.APIProject;
    else if (e.target.id === 'RevertPRODBAPI')
      target = Constants.ProdEnvB + '_' + Constants.APIProject;
    else if (e.target.id === 'RevertTESTRSAPI')
      target = Constants.TestEnv + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'RevertSTAGINGRSAPI')
      target = Constants.StagingEnv + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'RevertPRODRSAPI')
      target = Constants.ProdEnv + '_' + Constants.RSAPIProject;
    else if (e.target.id === 'RevertPRODBRSAPI')
      target = Constants.ProdEnvB + '_' + Constants.RSAPIProject;

    if (!window.confirm('Are you sure you want to REVERT ' + target + '?')) return;
    revert(target);
    //alert("Reverting: " + target)
  }

  SetRevertButtonStatus(buttonId) {
    document.getElementById(buttonId).disabled = true;
  }

  ToggleDetail() {
    this.setState({ showDetail: !this.state.showDetail });
  }

  formatColumn1(cell, row) {
    return <div className="LabelColumn">{cell}</div>;
  }

  populateCell(project, environment) {
    var deployButtonId = 'Deploy' + environment + project;
    var revertButtonId = 'Revert' + environment + project;

    var commitClassName = 'latestCommit';
    if (!document.getElementById('showDetail').checked) commitClassName = 'invisible';
    var prevDeployClassName = 'previousDeployment';
    if (!document.getElementById('showDetail').checked) prevDeployClassName = 'invisible';

    return (
      <div className="TableCellSmallFont">
        <DeploymentStatusItem
          method="get"
          token={this.props.token}
          environment={environment}
          project={project}
          previousVersion="false"
        />

        <br />
        <div className={commitClassName}>
          <Button className="MainButton" onClick={this.Deploy} id={deployButtonId}>
            Deploy
          </Button>
          <GitLatestCommit environment={environment} project={project} />
        </div>

        <br />
        {project != AdminProject && project != DMSProject &&
        <div className={prevDeployClassName}>


          <Button className="MainButton" onClick={this.Revert} id={revertButtonId}>
            Revert
          </Button>

          <br />
          <br />
          <i>
            <b>Previous Deployment</b>
          </i>
          <br />
          <DeploymentStatusItem
            action={this.SetRevertButtonStatus}
            buttonId={revertButtonId}
            method="get"
            token={this.props.token}
            environment={environment}
            project={project}
            previousVersion="true"
          />

        </div>
      }
      </div>
    );
  }

  populateDevColumn(cell, row, enumObject, index) {
   return this.populateCell(row.label, Constants.TestEnv);
  }

  populateStagingColumn(cell, row, enumObject, index) {
    if (row.label === Constants.AdminProject || row.label === Constants.DMSProject) return null;
    else return this.populateCell(row.label, Constants.StagingEnv);
  }

  populateProdColumn(cell, row, enumObject, index) {
    if (row.label === Constants.AdminProject) return null;
    else if (row.label === Constants.DMSProject) {
      var commitClassName = 'latestCommit';
      if (!document.getElementById('showDetail').checked) commitClassName = 'invisible';
      return (
        <div className="TableCellSmallFont">
          <div>
            <DeploymentStatusItem
              method="get"
              token={this.props.token}
              environment={Constants.ProdEnv}
              project={Constants.DMSProject}
            />
            <br />
            <div className={commitClassName}>
              <Button className="MainButton" onClick={this.Deploy} id="DeployPRODDMS">
                Deploy
              </Button>
              <br />
              <br />
              <i>
                <b>Deploy build artifact from staging</b>
              </i>
              <br />
            </div>
          </div>
        </div>
      );
    } else return this.populateCell(row.label, Constants.ProdEnv);
  }

  populateProdColumnB(cell, row, enumObject, index) {
    if (row.label === Constants.AdminProject) return null;
    else if (row.label === Constants.DMSProject) {
      var commitClassName = 'latestCommit';
      if (!document.getElementById('showDetail').checked) commitClassName = 'invisible';
      return (
        <div className="TableCellSmallFont">
          <div>
            <DeploymentStatusItem
              method="get"
              token={this.props.token}
              environment={Constants.ProdEnvB}
              project={Constants.DMSProject}
            />
            <br />
            <div className={commitClassName}>
              <Button className="MainButton" onClick={this.Deploy} id="DeployPRODBDMS">
                Deploy
              </Button>
              <br />
              <br />
              <i>
                <b>Deploy build artifact from staging</b>
              </i>
              <br />
            </div>
          </div>
        </div>
      );
    } else return this.populateCell(row.label, Constants.ProdEnvB);
  }

  componentDidMount() {
    this.setState({
      data: [
        {
          label: Constants.WebProject,
          test: Constants.TestEnv,
          staging: Constants.StagingEnv,
          prod: Constants.ProdEnv,
          prodB: Constants.ProdEnvB,
        },
        { label: Constants.MobileProject },
        { label: Constants.APIProject },
        { label: Constants.RSAPIProject },
        { label: Constants.AdminProject },
        { label: Constants.DMSProject },
      ],
    });
  }

  render() {
    return (
      <div>
        <BootstrapTable data={this.state.data}>
          <TableHeaderColumn width="75" dataField="label" dataFormat={this.formatColumn1} isKey>
            <ToggleButton className="checkbox" id="showDetail" type="checkbox" checked={this.state.showDetail} onChange={this.ToggleDetail}>
              {' '}
              Detail
            </ToggleButton>
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="test" dataFormat={this.populateDevColumn}>
            Test
          </TableHeaderColumn>
          <TableHeaderColumn
            width="150"
            dataField="staging"
            dataFormat={this.populateStagingColumn}
          >
            Staging
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="prod" dataFormat={this.populateProdColumn}>
            ProdA
          </TableHeaderColumn>
          <TableHeaderColumn width="150" dataField="prodB" dataFormat={this.populateProdColumnB}>
            ProdB
          </TableHeaderColumn>
        </BootstrapTable>
        <br />
      </div>
    );
  }
}
