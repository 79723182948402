import React, { Component } from 'react';

export default class DBUpdate extends Component {
  componentDidMount(newProps) {
  }

  render() {
    return (
      <div className="Dbupdate">
        <div className="lander">
          <div className="leftColumn">
            <h4>&nbsp;&nbsp;<br />DBUpdate TODO</h4>
            <br />
          </div>
        </div>
      </div>
    );
  }
}
