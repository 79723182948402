import Urls from './../Common/Urls';

export function LoginUser(credentials) {



  return fetch(credentials.url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      ClientId: 'TRMSDevClientId',
    },
    body: JSON.stringify(credentials),
  }).then(response => {
    return response;
  });
}
