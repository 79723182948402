import React, { Component } from 'react';
import { getLatestCommit } from '../Api/githubApi';
import '../assets/css/common.css';
import '../assets/css/home.css';

const Constants = require('./../Common/Constants');

export default class GitLatestCommit extends Component {
  constructor(props) {
    super(props);
    this.state = { requestFailed: false };
  }

  componentDidMount(newProps) {
    if (this.props.environment !== Constants.ProdEnv) {
      getLatestCommit(this.props.environment, this.props.project)
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            this.setState({ requestFailed: true });
            return response.statusText;
          }
        })
        .then(json => {
          this.setState({ data: json });
        });
    }
  }

  render() {
    if (this.props.environment === Constants.ProdEnv) {
      return (
        <div>
          <br />
          <i>
            <b>Deploy build artifact from staging</b>
          </i>
        </div>
      );
    }

    if (this.state.requestFailed) return <p> Failed: {this.state.data}</p>;
    if (!this.state.data) return <p> Loading...</p>;
    if (this.props.environment !== Constants.ProdEnv && this.props.environment != Constants.ProdEnvB) {
      try {
        return (
          <div>
            <br />
            <i>
              <b>Deploy latest commit</b>
            </i>
            <br />
            Author: {this.state.data.commit.author.name}
            <br />
            Date: {this.state.data.commit.author.date}
            <br />
            Message: {this.state.data.commit.message}
          </div>
        );
      } catch (error) {
        console.log(error);
        return null;
      }
    }
    return null;
  }
}
