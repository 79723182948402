// Routes for production

export const currentConn = 'PRODUCTION';
export const baseUrlTest = 'https://trmstest.com/';
export const baseUrlStaging = 'https://staging.trmstest.com/';
export const baseUrlProd = 'https://tofino1.com/';
export const baseUrlDeployProdA = 'https://deploya.tofino1.com/';
export const baseUrlDeployProdB =  'https://deployb.tofino1.com/';

/*
export const currentConn ="TESTDR";
export const baseUrlTest = 'https://test.trmsdr.com/';
export const baseUrlStaging = 'https://staging.trmsdr.com/';
export const baseUrlProd = 'https://trmsdr.com/';
export const baseUrlDeployProdA = 'https://deploya.trmsdr.com/';
export const baseUrlDeployProdB =  'https://deployb.trmsdr.com/';
*/
