import React, { Component } from 'react';

export default class SystemUsage extends Component {
  componentDidMount(newProps) {

  }

  render() {
    return (
      <div className="sytemUsage">
        <div className="lander">
          <div className="leftColumn">
            <h4>&nbsp;&nbsp;<br />System Usage TODO</h4>
            <br />
          </div>
        </div>
      </div>
    );
  }
}
