module.exports = {
  authSecret: 'TRMS-DEPLOYMENT-2018-API-SECRET',
  TestEnv: 'TEST',
  StagingEnv: 'STAGING',
  ProdEnv: 'PROD',
  ProdEnvB: 'PRODB',
  LegacyEnv: 'LEGACY',

  WebProject: 'WEB',
  MobileProject: 'MOBILE',
  APIProject: 'API',
  RSAPIProject: 'RSAPI',
  AdminProject: 'ADMIN',
  DMSProject: 'DMS',

  LanguageTableTest: 'LanguageTableTest',
  LanguageTableStaging: 'LanguageTableStaging',
  LanguageTableProd: 'LanguageTableProd',
  LanguageTableProdB: 'LanguageTableProdB',
};
