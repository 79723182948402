import React, { Component } from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { reloadLanguages } from '../Api/languageApi';
import LanguageStringCount from '../Components/languageCount';
import LanguageStrings from '../Components/languageStrings';
import '../assets/css/common.css';
import '../assets/css/language.css';
import '../..//node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';

const Constants = require('./../Common/Constants');

export default class Localization extends Component {
  constructor(props) {
    super(props);
    this.ReloadLanguages = this.ReloadLanguages.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.populateCell = this.populateCell.bind(this);
    this.populateDevColumn = this.populateDevColumn.bind(this);
    this.populateStagingColumn = this.populateStagingColumn.bind(this);
    this.populateProdColumn = this.populateProdColumn.bind(this);
    this.populateProdColumnB = this.populateProdColumnB.bind(this);
    this.state = { data: [] };
    this.state = { languageIdChanged: true };
  }

  handleChange = event => {
    var id = 0;
    try {
      id = document.getElementById('languageId').value;
      if (isNaN(id) || id < 0) alert('Enter a valid number');
      else this.setState({ languageIdChanged: !this.state.languageIdChanged });
    } catch (exception) {
      alert(exception.message);
    }
    return false;
  };

  ReloadLanguages(e) {
    try {
      var environment = '';
      if (e.target.id === 'reloadTEST') environment = Constants.TestEnv;
      else if (e.target.id === 'reloadSTAGING') environment = Constants.StagingEnv;
      else if (e.target.id === 'reloadPROD') environment = Constants.ProdEnv;
      else if (e.target.id === 'reloadPRODB') environment = Constants.ProdEnvB;

      reloadLanguages(sessionStorage.getItem('token'), environment).then(response => {
        if (!response.ok) {
          alert('Request failed: ' + response.status);
          return;
        }
      });

      alert('Languages reloaded for ' + environment + ' Note: It takes a minute to be fully loaded');
    } catch (e) {
      alert(e.message);
    }
  }

  populateColumn1(cell, row) {
    if (row.label === 'Id') {
      return <div className="LabelColumn" />;
    } else return <div className="LabelColumn">{cell}</div>;
  }

  populateCell(environment, label) {
    var buttonId = 'reload' + environment;
    if (label === 'Count') {
      return <LanguageStringCount token={sessionStorage.getItem('token')} environment={environment} />;
    } else if (label === 'Action') {
      return (
        <Button onClick={this.ReloadLanguages} id={buttonId}>
          Reload
        </Button>
      );
    } else if (label === 'String 673') {
      return (
        <div>
          <LanguageStrings environment={environment} id={673} />
        </div>
      );

    } else {
      var id = 0;
      try {
        id = document.getElementById('languageId').value;
        return (
          <div>
            <LanguageStrings environment={environment} id={id} />
          </div>
        );
      } catch (exception) {
        return 'Unable to find: ' + id;
      }
    }
  }

  populateDevColumn(cell, row, enumObject, index) {
    return this.populateCell(Constants.TestEnv, row.label);
  }

  populateStagingColumn(cell, row, enumObject, index) {
    return this.populateCell(Constants.StagingEnv, row.label);
  }

  populateProdColumn(cell, row, enumObject, index) {
    return this.populateCell(Constants.ProdEnv, row.label);
  }

  populateProdColumnB(cell, row, enumObject, index) {
    return this.populateCell(Constants.ProdEnvB, row.label);
  }

  componentDidMount(newProps) {
    this.setState({
      data: [
        {
          label: 'Count',
          test: 'Test',
          staging: 'Staging',
          prod: 'Prod',
        },
        {
          label: 'Action',
        },
        {
          label: 'String 673',
        },
        {
          label: (
            <div id="idLabel">
              Language Id
              <FormGroup controlId="languageId" >
                <FormControl type="text" defaultValue="0" onBlur={this.handleChange} />
              </FormGroup>
            </div>
          ),
        },
      ],
    });
  }

  render() {
    return (
      <form onSubmit={this.handleChange}>
        <div className="Language">
          <div className="main">
            <div className="ContentSection">
              <h4>
                <br />
                Language Cache
              </h4>
              <br />
            </div>
            <div className="ContentSection">
              <BootstrapTable data={this.state.data}>
                <TableHeaderColumn
                  width="200"
                  dataField="label"
                  dataFormat={this.populateColumn1}
                  isKey
                />
                <TableHeaderColumn dataField="dev" dataFormat={this.populateDevColumn}>
                  Test
                </TableHeaderColumn>
                <TableHeaderColumn dataField="staging" dataFormat={this.populateStagingColumn}>
                  Staging
                </TableHeaderColumn>
                <TableHeaderColumn dataField="prod" dataFormat={this.populateProdColumn}>
                  Prod
                </TableHeaderColumn>
                <TableHeaderColumn dataField="prodb" dataFormat={this.populateProdColumnB}>
                  ProdB
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
