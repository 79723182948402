import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { LoginUser } from '../Api/userAPI';
import '../assets/css/login.css';
import Urls from './../Common/Urls';

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
    };
  }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  setLoginData(data) {
    if (!data.user.userIsInRoleAdmin2) {
      alert('Role Error');
      this.props.navigate('/login');
      return;
    }
    sessionStorage.setItem('isAuthenticated', true);
    sessionStorage.setItem('token', data.authenticationJwt);
    this.props.navigate('/home');
  }

  handleSubmit = event => {
    event.preventDefault();
console.log ("***",Urls.currentConn)
    var credentials = {
      url: Urls.loginTofino,
      tenant: 'tofino',
      username: this.state.username,
      password: this.state.password,
    };

    try {
      return LoginUser(credentials).then(response => {
        if (!response.ok) {
          console.log('Request failed - trying trmstest: ' + response.status + ' ' + Urls.login);
          credentials.url = Urls.loginTrmstest;

          return LoginUser(credentials).then(response => {
            if (!response.ok) {
              alert('Request failed' + response.status + ' ' + Urls.login);
            }
            return response.json().then(data => {
              this.setLoginData(data);
            });
          });
        }
        return response.json().then(data => {
          this.setLoginData(data);
        });
      });
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    return (
      <div className="Login">
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="username">
            <FormLabel className="control">Username</FormLabel>
            <FormControl
              autoFocus
              type="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password">
            <FormLabel className="control">Password</FormLabel>
            <FormControl value={this.state.password} onChange={this.handleChange} type="password" />
          </FormGroup>
          <Button className="control" disabled={!this.validateForm()} type="submit">
            Login
          </Button>
        </form>
      </div>
    );
  }
}

function WithNavigate(props) {
  let navigate = useNavigate();
  return <Login {...props} navigate={navigate} />;
}

export default WithNavigate;
