switch (window.location.hostname.toLowerCase()) {
  case 'admin.trmstest.com':
    module.exports = require('./config-prod.js');
    break;

  case 'admin.trmsdr.com':
    module.exports = require('./config-dr');
    break;

  default:
    module.exports = require('./config-dev');
    break;
}
