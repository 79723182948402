import React, { Component } from 'react';
import DeploymentStatusWeb from '../Components/deploymentStatusWeb';
import DeploymentStatusTable from '../Components/deploymentStatusTable';
import '../assets/css/common.css';
import '../assets/css/home.css';
import Urls from './../Common/Urls';
const Constants = require('./../Common/Constants');

export default class Home extends Component {
  componentDidMount(newProps) {
  }

  render() {
    return (
      <div className="Home">
        <div className="main">
          <div className="ContentSection">
            <br />
            <DeploymentStatusWeb environment={Constants.LegacyEnv} />
            <br /><b> Current connection:  {Urls.currentConnection}</b>
          </div>
          <div className="ContentSection">
            <DeploymentStatusTable token={ sessionStorage.getItem('token')} />
          </div>
        </div>
      </div>
    );
  }
}

